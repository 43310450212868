import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { LoginWrapper, FormWrapper, SignInText } from './Style';
import { API_URL, postRequest } from "../../remote";
import { NotificationAlert, NotificationType, Salt } from "../../common/Utils";
import { AES } from "crypto-js";
import { STORAGE, APP_URL } from "../../common/Constant";
import { useHistory } from "react-router-dom";
import Logo from '../../assets/logo.svg';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { ApiHandler } from "../../remote/Api";

const Login = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState<boolean>(false);
	const onFinish = async (data: any) => {
		try {
			setLoading(true);
			const response = await postRequest(API_URL.LOGIN, data);
			if (!response.data.status) {
				NotificationAlert(response.data.message, NotificationType.ERROR);
				return;
			}
			var ciphertext = AES.encrypt(JSON.stringify(response.data.data), Salt).toString();
			ApiHandler.defaults.headers.common['Authorization'] = `${response.data.data.token}`;
			localStorage.setItem(STORAGE, ciphertext);
			history.push(APP_URL.NEWS);

		} catch (error) {
			NotificationAlert(error.message, NotificationType.ERROR);
		} finally {
			setLoading(false);
		}
	};

	return (
		<LoginWrapper>
			<FormWrapper>
				<div className="center"><img width="200" src={Logo} alt="News" /></div>
				<SignInText>Login to Dashboard</SignInText>

				<Form
					form={form}
					name="normal_login"
					className="login-form"
					initialValues={{ remember: true }}
					onFinish={onFinish}
				>
					<Form.Item
						name="username"
						rules={[{ required: true, message: 'Please input your Username!' }]}
					>
						<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: 'Please input your Password!' }]}
					>
						<Input
							prefix={<LockOutlined className="site-form-item-icon" />}
							type="password"
							placeholder="Password"
						/>
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
							Log in
						</Button>
					</Form.Item>
				</Form>
			</FormWrapper>
		</LoginWrapper>
	);
};
export default Login;
