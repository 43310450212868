import { APP_URL, STORAGE } from "../../common/Constant";
import { HeaderWrapper, Logout } from './Style';
import { MenuFoldOutlined, MenuUnfoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import { HeaderProps } from './Model';
import Logo from '../../assets/logo.svg';
import { Switch } from 'antd';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HeaderComponent = ({ collapsed, handleCollpase }: HeaderProps) => {
	const { i18n } = useTranslation();
	const history = useHistory();
	const [language, setLanguage] = useState("ml");

	const handleLogout = () => {
		localStorage.removeItem(STORAGE);
		history.push(APP_URL.LOGIN);
	}

	const changeLanguage = () => {
		let lang = language === "ml" ? "en" : "ml";
		setLanguage(lang);
		i18n.changeLanguage(lang)
	}

	return (
		<HeaderWrapper className="site-layout-background">
			{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
				className: 'trigger',
				onClick: () => handleCollpase(!collapsed)
			})}
			<img className="logo-main" width="200" src={Logo} alt="Logo" />
			<div className="language-switch">
				<Switch
					checkedChildren="ENGLISH"
					unCheckedChildren="മലയാളം"
					defaultChecked
					onChange={changeLanguage}
				/></div>
			<div>

				<div onClick={handleLogout}>
					<Logout>
						{/* <Avatar size={30}>USER</Avatar> */}
						<PoweroffOutlined /> Logout
					</Logout>
				</div>
			</div>
		</HeaderWrapper>
	);
};
export default HeaderComponent;
