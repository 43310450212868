import { API_URL } from "./Constant";
import Axios from "axios";
export const ApiHandler = Axios.create({
   baseURL: "http://mannur-api.i-wo.in/api"
  // baseURL: "http://mannur-dev-api.i-wo.in/api"

})
export const fetchConfig = async () => {
    try {
        const response = await Axios.get(API_URL.FETCH);
        ApiHandler.defaults.baseURL = response.data.baseUrl;
    } catch (error) {

    }
}

export const postRequest = (url: string, data?: any, options?: any) => {
    return ApiHandler.post(url, data, options);
}

export const getRequest = (url: string, data?: any, options?: any) => {
    return ApiHandler.get(url, options);
}

export const sendNotification = (title, description, imageUrl, type =1,response=null) => {
    const pushKey = "Bearer AAAAAHgryJA:APA91bEevo-zZqqogtUTcnuDK5acM3BpKCw8SSj0Q1pdvicBgoTEVNfuilby-oUh_0FltBqei324JNkBorb6sfvAPMAVrOX41NcXe-L_QH74hf7lQxEsbPEIXDouW3a9LmYjTucr9p1c";
    const instance = Axios.create({
        baseURL: ''
    });
    instance.defaults.headers.common['Authorization'] = pushKey;
    let body = {
        "notification": {
            "title": title,
            "body": description,
            "image": response?.url,
            "message": "Push url"
        },
        "data": {
            "type": type,
            "id": response?.id,
            "rowData":response
        },
        "android": {
            "priority": "high"
        },
        "to": "/topics/users"
    }
    instance.post("https://fcm.googleapis.com/fcm/send", body).then(value => {

    });
}
