import React, { useEffect } from 'react';
import './App.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import { fetchConfig, ApiHandler } from "./remote/Api";
import { postRequest, API_URL } from "./remote";
import Login from './container/login/Login';
import Home from './container/home/Home';
import { getUserInfo } from "./common/Utils";


const App: React.FC = () => {
	const history = useHistory();

	useEffect(() => {
		const Init = async () => {
			await fetchConfig();
		}
		Init();
	}, []);

	useEffect(() => {
		const isUserLogined = async () => {
			try {
				if (!localStorage.getItem("userInfo")) {
					history.push("/login");
				} else {
					const userInfo = getUserInfo(localStorage.getItem("userInfo"));
					ApiHandler.defaults.headers.common['Authorization'] = `${userInfo.token}`;
					const response = await postRequest(API_URL.SESSION_VALID);
					if (!response.data.status) {
						localStorage.removeItem("userInfo");
						history.push("/login");
					}
				}
			} catch (error) {
				localStorage.removeItem("userInfo");
				history.push("/login");
			} finally {

			}
		}
		isUserLogined();
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);



	return (
		<div className="App">
			<Switch>
				<Route path="/login" component={Login} />
				<Route path="/" component={Home} />
			</Switch>
		</div>
	);
}

export default App;
