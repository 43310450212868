import { Layout } from 'antd';
import React, { useState } from 'react';
import SideBar from './Sidebar';
import Header from './Header';
import Content from './Container';

const Home = () => {
	const [collapsed, setCollapsed] = useState<boolean>(false);
	return (
		<Layout style={{ }}>
			<Header collapsed={collapsed} handleCollpase={(value) => setCollapsed(value)} />
			<Layout className="site-layout">
				<SideBar collapsed={collapsed} />
				<Content />
			</Layout>
		</Layout>
	);
};
export default Home;
