import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import "./config/lang";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faNewspaper, faHeart, faBus, faCar, faStethoscope, faAmbulance, faBell, faUsers, faUser, faBuilding, faTrain, faUmbrella, faBook, faLightbulb, faHandshake } from '@fortawesome/free-solid-svg-icons'
library.add(faNewspaper,faHeart, faBus, faCar, faStethoscope, faAmbulance, faBell, faUsers, faUser, faBuilding, faTrain, faUmbrella, faBook, faLightbulb, faHandshake);


ReactDOM.render(
	// <React.StrictMode>
	<Router>
		<App />
	</Router>,
	// </React.StrictMode>
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
