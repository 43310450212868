import React, { Suspense, lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ContainerWrapper } from "./Style";
import { Spin } from "antd";

//News
const News = lazy(() => import("../news"));
//Blood donator
const BloodDonation = lazy(() => import("../bloodDonator"));
const Bustime = lazy(() => import("../busTime"));
const Vehicle = lazy(() => import("../Vehicle"));
const Hospitals = lazy(() => import("../hospitals"));
const Emergency = lazy(() => import("../emergency"));
const CovidSupport = lazy(() => import("../covidSupport"));
const Feedback = lazy(() => import("../feedback"));


const Information = lazy(() => import("../information"));

const Labours = lazy(() => import("../labours"));
const LaborType = lazy(() => import("../labours/LaborType"));

const Shops = lazy(() => import("../shops"));
const ShopType = lazy(() => import("../shops/ShopTypes"));

const TrainTime = lazy(() => import("../trainTime"));
const VehicleType = lazy(() => import("../Vehicle/VehicleType"));
const Ads = lazy(() => import("../ads"));
const Tourism = lazy(() => import("../tourism"));
const Creation = lazy(() => import("../creation"));
const Tips = lazy(() => import("../tips"));

const Union = lazy(() => import("../union"));
const UnionType = lazy(() => import("../union/UnionType"));
const UnionCategory = lazy(() => import("../union/Category"));

const Institutions = lazy(() => import("../institutions"));
const InstitutionsType = lazy(() => import("../institutions/InstitutionType"));

const Photos = lazy(() => import("../gallery/photos/index"));
// const PhotosCategory = lazy(() => import("../gallery/photos/AddPhotosCategory"));
const EditPhotos = lazy(() => import("../gallery/photos/EditPhotos"));


const GalleryCreation = lazy(() => import("../gallery/creation"));
const GalleryTips = lazy(() => import("../gallery/tips"));

const PhotosCategory = lazy(() => import("../gallery/photosCategory"));
const CreationCategory = lazy(() => import("../gallery/creationCategory"));

const BuySell = lazy(() => import("../buysell"));






const Container = () => {
	return (
		<ContainerWrapper className="site-layout-background">
			<Suspense fallback={<Spin />}>
				<Route path="/" exact render={() => (<Redirect to="/news" />)} />
				<Route path="/news" component={News} />
				<Route path="/blood-donators" component={BloodDonation} />
				<Route path="/bus-time" component={Bustime} />
				<Route path="/vehicle" component={Vehicle} />
				<Route path="/vehicle-type" component={VehicleType} />
				<Route path="/hospitals" component={Hospitals} />
				<Route path="/emergency" component={Emergency} />
				<Route path="/covid-support" component={CovidSupport} />
				<Route path="/feedback" component={Feedback} />


				<Route path="/information" component={Information} />
				<Route path="/labours" component={Labours} />
				<Route path="/labours-type" component={LaborType} />
				<Route path="/shops" component={Shops} />
				<Route path="/shop-type" component={ShopType} />
				<Route path="/train-time" component={TrainTime} />
				<Route path="/ads" component={Ads} />
				<Route path="/tourism" component={Tourism} />
				<Route path="/creation" component={Creation} />
				<Route path="/tips" component={Tips} />
				<Route path="/union" component={Union} />
				<Route path="/union-type" component={UnionType} />
				<Route path="/union-category" component={UnionCategory} />
				<Route path="/institutions" component={Institutions} />
				<Route path="/institutions-type" component={InstitutionsType} />


				<Route path="/gallery/photos" component={Photos} />
				{/* <Route path="/gallery/photos/PhotosCategory" component={PhotosCategory} /> */}
				<Route path="/gallery/edit-photos" component={EditPhotos} />
				
				<Route path="/gallery/PhotoCategory" component={PhotosCategory} />
				
				<Route path="/gallery/creation" component={GalleryCreation} />				
				<Route path="/gallery/creations/CreationCategory" component={CreationCategory} />
				<Route path="/gallery/tips" component={GalleryTips} />				


				<Route path="/buy-sell" component={BuySell} />

			



			</Suspense>
		</ContainerWrapper>
	);
};
export default Container;
