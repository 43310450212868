import styled from 'styled-components';
import { Layout } from 'antd';

export const HeaderWrapper = styled(Layout.Header)`
    display:flex;
    padding: 0;
    align-items:center;
    justify-content:space-between;
    background:var(--color-2);
`;

export const Logout = styled.div`
    color: var(--color-3);
    font-size: 13px;
    margin-right:40px;
    cursor: pointer;  
`;

export const SideWrapper = styled(Layout.Sider)`
    background:var(--color-3);
`;

export const ContainerWrapper = styled(Layout.Content)`
    margin:12px;
    padding: 12px;
    min-height: 280;
`;

export const LogoWrapper=styled.div`
  //border:2px solid red;
`;
