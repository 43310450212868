import { notification } from "antd";
import { AES, enc } from "crypto-js";



export const NotificationType = {
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
    ERROR: "error"
}

export const NotificationAlert = (message: string, type: string = NotificationType.SUCCESS) => {
    notification[type]({
        message: 'Notification',
        description: message
    });
}

export const Salt = "manuure_pp_pjt";

export const getUserInfo = (data) => {
    var bytes = AES.decrypt(data, Salt);
    var decryptedData = JSON.parse(bytes.toString(enc.Utf8));
    return data ? decryptedData : {};
}




