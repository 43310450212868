import {
	AlertOutlined,
	BookOutlined,
	CarOutlined,
	DashboardOutlined,
	ShopOutlined,
	UsergroupAddOutlined,
	SoundOutlined,
	ShoppingCartOutlined,
	PictureOutlined,
	FileJpgOutlined,
	AppstoreFilled
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu } from 'antd';
import { SideWrapper } from './Style';
import { SidebarProps } from './Model';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
const { SubMenu } = Menu;
const SideBarComponent = ({ collapsed }: SidebarProps) => {
	const history = useHistory();
	const { t } = useTranslation();
	const [currentMenu, setSelectedMenu] = useState("/news");
	useEffect(() => {
		setSelectedMenu(history.location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleRoute = (path: string) => {
		setSelectedMenu(path);
		history.push(path);
	};

	return (
		<SideWrapper width={250}
			trigger={null}
			collapsible collapsed={collapsed}
		>
			<Menu theme="light" mode="inline" selectedKeys={[currentMenu]} >

				<SubMenu key="sub1" icon={<DashboardOutlined />} title="General Info">
					<Menu.Item key="/news" icon={<FontAwesomeIcon icon="newspaper" />} onClick={() => handleRoute("/news")}>{t('news')}</Menu.Item>
					<Menu.Item key="/information" icon={<FontAwesomeIcon icon="bell" />} onClick={() => handleRoute("/information")}>{t('Information')}</Menu.Item>
					{/* <Menu.Item key="/tips" icon={<FontAwesomeIcon icon="lightbulb" />} onClick={() => handleRoute("/tips")}>{t('tips')}</Menu.Item> */}
					{/* <Menu.Item key="/creation" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/creation")}>{t('creation')}</Menu.Item> */}
				</SubMenu>

				<SubMenu key="sub2" icon={<AlertOutlined />} title="Emergency">
					<Menu.Item key="/hospitals" icon={<FontAwesomeIcon icon="stethoscope" />} onClick={() => handleRoute("/hospitals")}>{t('Hospitals')}</Menu.Item>
					<Menu.Item key="/emergency" icon={<FontAwesomeIcon icon="ambulance" />} onClick={() => handleRoute("/emergency")}>{t('Emergency')}</Menu.Item>
					<Menu.Item key="/blood-donators" icon={<FontAwesomeIcon icon="heart" />} onClick={() => handleRoute("/blood-donators")}>{t('blood donators')}</Menu.Item>
					<Menu.Item key="/covid-support" icon={<FontAwesomeIcon icon="ambulance" />} onClick={() => handleRoute("/covid-support")}>{t('Covid Support')}</Menu.Item>
				</SubMenu>

				<SubMenu key="sub3" icon={<CarOutlined />} title="Travel Info">
					<Menu.Item key="/bus-time" icon={<FontAwesomeIcon icon="bus" />} onClick={() => handleRoute("/bus-time")}>{t('Bus time')}</Menu.Item>
					<Menu.Item key="/train-time" icon={<FontAwesomeIcon icon="train" />} onClick={() => handleRoute("/train-time")}>{t('train time')}</Menu.Item>
					<Menu.Item key="/vehicle" icon={<FontAwesomeIcon icon="car" />} onClick={() => handleRoute("/vehicle")}>{t('Vehicle')}</Menu.Item>
					<Menu.Item key="/vehicle-type" icon={<FontAwesomeIcon icon="car" />} onClick={() => handleRoute("/vehicle-type")}>{t('Vehicle Type')}</Menu.Item>
				</SubMenu>

				<SubMenu key="sub4" icon={<UsergroupAddOutlined />} title="Profession Info">
					<Menu.Item key="/labours" icon={<FontAwesomeIcon icon="user" />} onClick={() => handleRoute("/labours")}>{t('labors')}</Menu.Item>
					<Menu.Item key="/labours-type" icon={<FontAwesomeIcon icon="users" />} onClick={() => handleRoute("/labours-type")}>{t('labor type')}</Menu.Item>

					<Menu.Item key="/union" icon={<FontAwesomeIcon icon="handshake" />} onClick={() => handleRoute("/union")}>{t('union')}</Menu.Item>
					<Menu.Item key="/union-category" icon={<FontAwesomeIcon icon="users" />} onClick={() => handleRoute("/union-category")}>{t('union category')}</Menu.Item>
					<Menu.Item key="/union-type" icon={<FontAwesomeIcon icon="handshake" />} onClick={() => handleRoute("/union-type")}>{t('union type')}</Menu.Item>

					<Menu.Item key="/institutions" icon={<FontAwesomeIcon icon="handshake" />} onClick={() => handleRoute("/institutions")}>{t('institutions')}</Menu.Item>
					<Menu.Item key="/institutions-type" icon={<FontAwesomeIcon icon="handshake" />} onClick={() => handleRoute("/institutions-type")}>{t('institutions type')}</Menu.Item>
				</SubMenu>

				<SubMenu key="sub5" icon={<ShopOutlined />} title="Business Info">
					<Menu.Item key="/shops" icon={<FontAwesomeIcon icon="building" />} onClick={() => handleRoute("/shops")}>{t('shops')}</Menu.Item>
					<Menu.Item key="/shop-type" icon={<FontAwesomeIcon icon="building" />} onClick={() => handleRoute("/shop-type")}>{t('shop type')}</Menu.Item>
					<Menu.Item key="/tourism" icon={<FontAwesomeIcon icon="umbrella" />} onClick={() => handleRoute("/tourism")}>{t('tourism')}</Menu.Item>
					<Menu.Item key="/buy-sell" icon={<FontAwesomeIcon icon="umbrella" />} onClick={() => handleRoute("/buy-sell")}>{t('buysell')}</Menu.Item>
					<Menu.Item key="/ads" icon={<SoundOutlined />} onClick={() => handleRoute("/ads")}>{t('ads')}</Menu.Item>
				</SubMenu>

				<SubMenu key="sub6" icon={<AppstoreFilled />} title="Gallery">
					<Menu.Item key="/gallery/photos" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/gallery/photos")}>{t('photos')}</Menu.Item>
					{/* <Menu.Item key="/gallery/photo-category" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/gallery/photo-category")}>{t('photos category')}</Menu.Item> */}
					{/* <Menu.Item key="/gallery/edit-photos" icon={<FontAwesomeIcon icon="newspaper" />} onClick={() => handleRoute("/gallery/edit-photos")}>{t('edit photos')}</Menu.Item> */}
					<Menu.Item key="/gallery/PhotoCategory" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/gallery/PhotoCategory")}>{t('photos category')}</Menu.Item>
					
					
					
					<Menu.Item key="/gallery/creation" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/gallery/creation")}>{t('creation')}</Menu.Item>
					<Menu.Item key="/gallery/CreationCategory" icon={<FontAwesomeIcon icon="book" />} onClick={() => handleRoute("/gallery/creations/CreationCategory")}>{t('creation category')}</Menu.Item>
					
					<Menu.Item key="/gallery/tips" icon={<FontAwesomeIcon icon="lightbulb" />} onClick={() => handleRoute("/gallery/tips")}>{t('tips')}</Menu.Item>
					
	</SubMenu>

				<Menu.Item key="/feedback" icon={<BookOutlined />} onClick={() => handleRoute("/feedback")}>{t('Feedback')}</Menu.Item>
				
			</Menu>
		</SideWrapper>
	);
};
export default SideBarComponent;
