export const APP_URL = {
	LOGIN: '/login',
	NEWS: '/news',
	BLOOD: '/blood-donators',
	BUSTIME: '/bus-time',
	VEHICLE: '/vehicle',
	HOSPITALS: '/hospitals',
	EMERGENCY: "/emergency",
	COVID_SUPPORT: "/covid-support",
	FEEDBACK: "/feedback",
	INFORMATION: "/information",
	LABOURS: "/labours",
	SHOPS: "/shops",
	TRAIN_TIME: "/train-time"
};

export const STORAGE = "userInfo";