export const API_URL = {
    FETCH: "/config.json",
    LOGIN: "/login",
    SESSION_VALID: "/sessionvalid",

    NEWS: "/news",
    NEWS_CREATE: "/news/create",
    NEWS_FETCH_By_ID: "/news/fetchbyid",
    NEWS_DELETE: "/news/delete",
    NEWS_UPDATE: "/news/update",

    BLOOD_GROUP: "/bloodgroups",
    BLOOD_DONATOR: "/blooddonators",
    BLOOD_DONATOR_CREATE: "/blooddonators/create",
    BLOOD_DONATOR_UPDATE: "/blooddonators/update",
    BLOOD_DONATOR_DELETE: "/blooddonators/delete",
    BLOOD_DONATOR_FETCH: "/blooddonators/fetchbyid",

    EMERGENCY: "/emergency",
    EMERGENCY_CREATE: "/emergency/create",
    EMERGENCY_DELETE: "/emergency/delete",
    EMERGENCY_FETCH: "/emergency/fetch",
    EMERGENCY_UPDATE: "/emergency/update",

    HOSPITAL: "/hospitals",
    HOSPITAL_CREATE: "/hospitals/create",
    HOSPITAL_DELETE: "/hospitals/delete",
    HOSPITAL_FETCH: "/hospitals/fetch",
    HOSPITAL_UPDATE: "/hospitals/update",

    VEHICLE: "/vehicle",
    VEHICLE_CREATE: "/vehicle/create",
    VEHICLE_FETCH: "/vehicle/edit",
    VEHICLE_UPDATE: "/vehicle/update",
    VEHICLE_DELETE: "/vehicle/delete",

    VEHICLE_TYPE: "/vehicletypes",
    VEHICLE_TYPE_CREATE: "/vehicletype/create",
    VEHICLE_TYPE_FETCH: "/vehicletype/edit",
    VEHICLE_TYPE_UPDATE: "/vehicletype/update",
    VEHICLE_TYPE_DELETE: "/vehicletype/delete",

    INFORMATION: "/info",
    INFORMATION_CREATE: "/info/create",
    INFORMATION_DELETE: "/info/delete",
    INFORMATION_FETCH: "/info/fetch",
    INFORMATION_UPDATE: "/info/update",

    LABOR_TYPE: "/laborstypes",
    LABOR_TYPE_CREATE: "/laborstypes/create",
    LABOR_TYPE_DELETE: "/laborstypes/delete",
    LABOR_TYPE_FETCH: "/laborstypes/fetch",
    LABOR_TYPE_UPDATE: "/laborstypes/update",

    LABOR: "/labors",
    LABOR_CREATE: "/labors/create",
    LABOR_DELETE: "/labors/delete",
    LABOR_FETCH: "/labors/fetch",
    LABOR_UPDATE: "/labors/update",

    SHOP_TYPE: "/shoptypes",
    SHOP_TYPE_CREATE: "/shoptypes/create",
    SHOP_TYPE_DELETE: "/shoptypes/delete",
    SHOPS_TYPE_FETCH: "/shoptypes/fetch",
    SHOP_TYPE_UPDATE: "/shoptypes/update",

    SHOPS: "/shops",
    SHOPS_CREATE: "/shops/create",
    SHOPS_DELETE: "/shops/delete",
    SHOPS_FETCH: "/shops/fetch",
    SHOPS_UPDATE: "/shops/update",

    ADS: "/ads/all",
    ADS_CREATE: "/ads/create",
    ADS_DELETE: "/ads/delete",

    TOURISM: "/tourism",
    TOURISM_CREATE: "/tourism/create",
    TOURISM_DELETE: "/tourism/delete",
    TOURISM_FETCH: "/tourism/fetch",
    TOURISM_UPDATE: "/tourism/update",

    CREATION: "/creation",
    CREATION_CREATE: "/creation/create",
    CREATION_DELETE: "/creation/delete",
    CREATION_FETCH: "/creation/fetch",
    CREATION_UPDATE: "/creation/update",

    UNION_TYPE: "/union_type",
    UNION_TYPE_CREATE: "/union_type/create",
    UNION_TYPE_DELETE: "/union_type/delete",
    UNION_TYPE_FETCH: "/union_type/fetch",
    UNION_TYPE_UPDATE: "/union_type/update",

    UNION_CATEGORY: "/union_category",
    UNION_CATEGORY_CREATE: "/union_category/create",
    UNION_CATEGORYDELETE: "/union_category/delete",
    UNION_CATEGORY_FETCH: "/union_category/fetch",
    UNION_CATEGORY_UPDATE: "/union_category/update",

    UNION_PEOPLE: "/union_people",
    UNION_PEOPLE_CREATE: "/union_people/create",
    UNION_PEOPLE_DELETE: "/union_people/delete",
    UNION_PEOPLE_FETCH: "/union_people/fetch",
    UNION_PEOPLE_UPDATE: "/union_people/update",

    TIPS: "/tips",
    TIPS_CREATE: "/tips/create",
    TIPS_DELETE: "/tips/delete",
    TIPS_FETCH: "/tips/fetch",
    TIPS_UPDATE: "/tips/update",

    INSTITUTIONS: "/Institutions",
    INSTITUTIONS_CREATE: "/Institutions/create",
    INSTITUTIONS_DELETE: "/Institutions/delete",
    INSTITUTIONS_FETCH: "/Institutions/fetch",
    INSTITUTIONS_UPDATE: "/Institutions/update",

    INSTITUTIONS_TYPE: "/Institutions_type",
    INSTITUTIONS_TYPE_CREATE: "/Institutions_type/create",
    INSTITUTIONS_TYPE_DELETE: "/Institutions_type/delete",
    INSTITUTIONS_TYPE_FETCH: "/Institutions_type/fetch",
    INSTITUTIONS_TYPE_UPDATE: "/Institutions_type/update",

    TRAINS: "/trains",
    TRAINS_CREATE: "/trains/create",
    TRAINS_DELETE: "/trains/delete",
    TRAINS_TYPE: "/trains/type",
    TRAINS_LOCATION: "/trains/location",
    TRAINS_FETCH: "/trains/fetch",
    TRAINS_UPDATE: "/trains/update",


    BUS: "/bustime",
    BUS_LOCATION: "/bustime/locations",
    BUS_CREATE: "/bustime/create",
    BUS_DELETE: "/bustime/delete",
    BUS_FETCH: "/bustime/fetch",
    BUS_UPDATE: "/bustime/update",

    COVID_CENTRE: "/covid_centers",
    COVID_CENTRE_CREATE: "/covid_centers/create",
    COVID_CENTRE_EDIT: "/covid_centers/fetch",
    COVID_CENTRE_DELETE: "/covid_centers/delete",
    COVID_CENTRE_UPDATE: "/covid_centers/update",

    FEEDBACK: "/feedbacks",
    FEEDBACK_DELETE: "/feedbacks/delete",


    PHOTOS_CATEGORY: "/photos_category",
    ADD_PHOTOS: "/new_photos",
    GET_PHOTOS_BY_CATEGORY: "/photos_by_categoryId",
    DELETE_PHOTOS: "/photos/delete",

    CREATION_CATEGORY: "/creation_categories",
    NEW_CREATIONS: "/new_creations",
    CREATIONS_LIST: "/creations/list",
    DELETE_CREATION: "/creations/delete",  
    UPDATE_CREATION: "/creations/update",
    FETCH_CREATIONBYID: "/creations/fetchbyid",

    
    NEW_CREATIONS_CATEGORY: "/creationcategory/create",
    CREATIONS_CATEGORY_LIST: "/creationcategory/list",
    CREATIONS_CATEGORY_FETCHBYID: "/creationcategory/fetchbyid",
    CREATIONS_CATEGORY_UPDATE: "/creationcategory/update",
    CREATIONS_CATEGORY_DELETE: "/creationcategory/delete",

    PHOTOS_CATEGORY_CREATE: "/photoscategory/create",
    PHOTOS_CATEGORY_LIST: "/photoscategory/list",
    PHOTOS_CATEGORY_FETCHBYID: "/photoscategory/fetchbyid",
    PHOTOS_CATEGORY_UPDATE: "/photoscategory/update",
    PHOTOS_CATEGORY_DELETE: "/photoscategory/delete",


    NEW_BUY_SELL: "/buy_sell",
    DELETE_BUY_SELL: "/buy_sell/delete",
    CREATE_BUY_SELL: "/buy_sell/create",
    BUY_SELL_FETCH_BY_ID: "/buy_sell/fetchbyid",
    UPDATE_BUY_SELL: "/buy_sell/update",

    UPLOAD_PICS: "/uploadPics",
    DELETE_PICS: "/deletePics",
    GET_PICS_BY_UID: "/getPicsById"


}
