import styled from 'styled-components';
import LoginImage from '../../assets/login-bg.png';

export const LoginWrapper = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #6045E2 url(${LoginImage});
	background-size: contain;
`;

export const FormWrapper = styled.div`
	border: 1px solid #ccc;
	padding: 40px;
	min-width: 500px;
	background: #fff;
	border-radius: 10px;
	@media (max-width: 768px) {
		min-width: 90%;
	  }
`;

export const SignInText = styled.h6`
	font-family: 'Poppins', sans-serif;
    font-weight:bold; 
    text-align:left;
    font-size:20px;
    padding:20px 0 5px 0;
`;
