import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    "news": "News",
                    "add news": "Add News",
                    "blood donators": "Blood Donators",
                    "Add New Blood donators": "Add Blood Donator",
                    "Bus time": "Bus Time",
                    "Add Bus time": "Add Bus Time",
                    "Vehicle Type": "Vehicle Type",
                    "Vehicle": "Vehicle",
                    "Add Vehicle": "Add Vehicle",
                    "Hospitals": "Hospitals",
                    "Add Hospitals": "Add Hospitals",
                    "Emergency": "Emergency",
                    "Add Emergency": "Add Emergency Number",
                    "Information": "Information",
                    "Add Information": "Add Information",

                    "Covid Support": "Covid-19 Support",
                    "Add Covid Support": "Add Covid-19 Support",

                    "Feedback": "Feedback",
                    "Add Feedback": "Add Feedback",
                    
                    "labors": "Labors",
                    "add labors": "Add Labors",
                    "labor type": "Labors type",
                    "add labor type": "Add Labors type",

                    "shops": "Shops",
                    "add shops": "Add Shops",
                    "shop type": "Shop type",

                    "train time": "Train Time",
                    "add train time": "Add Train Time",
                    "ads": "Advertisement",
                    "add ads": "Add Advertisement",
                    "tourism": "Tourism",
                    "add tourism": "Add Tourism",

                    "creation": "Creations",
                    "add creation": "Add Creations",

                    "union": "Union",
                    "add union": "Add Union",
                    "union type": "Union Type",
                    "add union type": "Add Union Type",
                    "union category":"Union Category",
                    "add union category":"Add Union Category",

                    "institutions": "Institutions",
                    "add institutions": "Add Institutions",
                    "institutions type": "Institution Type",
                    "add institutions type": "Add Institution Type",

                    "tips": "Tips",
                    "add tips": "Add New Tips",

                    "photos": "Photos",
                    "add photos": "Add New Photos",
                    "photos category": "Photos Category",

                    "buysell": "Buy & Sell",
                    "add buysell": "Add New Buy & Sell",
                    
                    "add category":"Add Category",
                    "creation category":"Creation Category"
                }
            },
            ml: {
                translation: {
                    "news": "വാർത്തകൾ",
                    "add news": "പുതിയ വാർത്തകൾ",
                    "blood donators": "രക്തദാതാക്കൾ ",
                    "Add New Blood donators": "പുതിയ രക്തദാതാക്കൾ",
                    "Bus time": "ബസ് സമയം ",
                    "Add Bus time": "പുതിയ ബസ് സമയം",

                    "Vehicle": "വാഹനങ്ങൾ",
                    "Add Vehicle": "പുതിയ വാഹനങ്ങൾ",
                    "Vehicle Type": "വാഹന തരം",
                    "Add Vehicle Type": "പുതിയ വാഹനങ്ങളുടെ തരം",

                    "Hospitals": "ആശുപത്രി",
                    "Add Hospitals": "പുതിയ ആശുപത്രികൾ",
                    "Emergency": "എമർജൻസി",
                    "Add Emergency": "പുതിയ എമർജൻസി നമ്പർ",

                    "Covid Support": "കോവിഡ്-19 സപ്പോർട്ട്",
                    "Add Covid Support": "പുതിയ കോവിഡ്-19 സപ്പോർട്ട്",

                    "Feedback": "ഫീഡ്‌ബാക്ക്",
                    "Add Feedback": "പുതിയ ഫീഡ്‌ബാക്ക്",

                    "Information": "അറിയിപ്പുകൾ",
                    "Add Information": "പുതിയ അറിയിപ്പുകൾ",

                    "labors": "തൊഴിലാളികൾ",
                    "add labors": "പുതിയ തൊഴിലാളികൾ",
                    "labor type": "തൊഴിലാളികളുടെ ജോലി",
                    "add labor type": "പുതിയ തൊഴിലാളികളുടെ ജോലി",

                    "shops": "ഷോപ്പുകൾ",
                    "add shops": "പുതിയ ഷോപ്പുകൾ",
                    "shop type": "ഷോപ്പുകളുടെ തരം",
                    "add shop type": "പുതിയ ഷോപ്പുകളുടെ തരം",


                    "train time": "ട്രെയിൻ സമയം",
                    "add train time": "പുതിയ ട്രെയിൻ സമയം",

                    "ads": "പരസ്യം",
                    "add ads": "പുതിയ പരസ്യം",

                    "tourism": "ടൂറിസം",
                    "add tourism": "പുതിയ ടൂറിസം",

                    "creation": "സൃഷ്ടികൾ ",
                    "add creation": "പുതിയ സൃഷ്ടികൾ",

                    "union": "യൂണിയൻ",
                    "add union": "പുതിയ യൂണിയൻ",
                    "union type": "യൂണിയൻ തരം",
                    "add union type": "പുതിയ യൂണിയൻ തരം",
                    "union category":"യൂണിയൻ വിഭാഗം",
                    "add union category":"പുതിയ യൂണിയൻ വിഭാഗം",

                    "institutions": "സ്ഥാപനങ്ങൾ",
                    "add institutions": "പുതിയ സ്ഥാപനങ്ങൾ",
                    "institutions type": "സ്ഥാപനങ്ങളുടെ തരം",
                    "add institutions type": "പുതിയ സ്ഥാപനങ്ങളുടെ തരം",

                    "photos": "ഫോട്ടോസ്",
                    "add photos": "പുതിയ ഫോട്ടോസ്",
                    "photos category": "ഫോട്ടോസ് വിഭാഗം",
                    "add photos category":"പുതിയ ഫോട്ടോസ് വിഭാഗം",

                    "tips": "ടിപ്സ് ",
                    "add tips": "പുതിയ ടിപ്സ്",

                    "buysell": "വാങ്ങാം വിൽക്കാം",
                    "add buysell": "പുതിയ വാങ്ങാം വിൽക്കാം",

                    "add category":"പുതിയ വിഭാഗം",
                    "creation category":"സൃഷ്ടികൾ വിഭാഗം"
                }
            },
        },
        lng: "ml",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });
export default i18n;